import React from "react"

import {
  Footer,
  Partners
} from "../pages/index.js"
import { Header } from "./header2.js"

export const Layout2 = ({ children }) => {
  return (
    <div className="max-w-full overflow-x-hidden">
      <Header />
      {children}
      <Partners />
      <Footer />
    </div>
  )
}
