import React, {
  useEffect,
  useState
} from "react"

import { BlogPost } from "../components/blog_post"
import { Layout2 } from "../components/layout2"
import SEO from "../components/seo"
import { Articles } from "../components/tc-insight2"
import { usePost } from "../utils/insights"
import { SendUsMessage } from "./"

export default function OurInsights({ location }) {
  console.log(location)
  const [search, setSearch] = useState("")
  useEffect(() => {
    const url = new URL(location.href)
    setSearch(url.searchParams.get("post"))
  }, [location.href])
  return (
    <Layout2>
      <SEO title="Our Insights" />
      {search ? <BlogPost id={search} /> : <AllPost />}
      <SendUsMessage />
    </Layout2>
  )
}
const AllPost = () => (
  <>
    <Hero />
    <Insights />
  </>
)

const Hero = () => {
  return (
    <div id="hero" className="relative nice-hero bg-right bg-cover">
      <h1 className="py-24 text-5xl font-semibold text-white text-center">
        Our Insights
      </h1>
      <p className="absolute text-pred bottom-0 mb-5 text-center w-full font-medium">
        {new Date().toString().slice(0, 15)}
      </p>
    </div>
  )
}

const Insights = () => {
  const [offset, setOffset] = useState(0)
  const posts = usePost({ type: "latest", limit: 12, offset })

  const decrementCurrent = () => setOffset(offset === 0 ? 0 : offset - 1)
  const incrementCurrent = () => setOffset(offset + 1)
  return (
    <div className="container">
      <div className="my-20">
        <div className="flex flex-wrap">
          <Articles blogPosts={posts} />
        </div>

        <div className="flex justify-center mt-8">
          <button
            onClick={decrementCurrent}
            className="border-2 border-gray-200 test-pred  px-2 h-12"
          >
            <span className="arrow-style">&larr;</span>
          </button>
          <span className="border px-4 flex items-center font-semibold">
            {offset + 1}
          </span>
          <button
            onClick={incrementCurrent}
            className="bg-pred text-white px-2 h-12"
          >
            <span className="arrow-style">&rarr;</span>
          </button>
        </div>
      </div>
    </div>
  )
}
