import React, {
  useEffect,
  useRef
} from "react"

import { usePost } from "../utils/insights"
import { OurInsights } from "./tc-insight2"

export const BlogPost = ({ id }) => {
  const post = usePost({ type: "single", id })
  return (
    <>
      <Hero
        title={post.single?.title?.rendered}
        bg={post.image(post.single)}
        date={post.single?.date}
      />
      <div className="my-20 mb-32 container px-12 text-justify">
        <div className="w-full md:w-10/12 lg:w-8/12 mx-auto">
          <div
            className="blog_post"
            dangerouslySetInnerHTML={{ __html: post.single?.content?.rendered }}
          ></div>
        </div>
      </div>
      <OurInsights />
    </>
  )
}

const Hero = ({ title, date, bg }) => {
  const bgRef = useRef()
  useEffect(() => {
    if (bg && bgRef.current) {
      bgRef.current.style.backgroundImage = `url(${bg})`
    }
  }, [bgRef, bg])
  return (
    <div
      id="hero"
      ref={bgRef}
      className="relative nice-hero bg-center bg-cover"
    >
      <div className="bg-black opacity-25 absolute h-full w-full z-0" />
      <h1 className="relative z-10 py-24 px-20 text-3xl font-medium text-white text-center">
        {title || "Our Insights"}
      </h1>
      <p className="absolute text-pred bottom-0 mb-5 text-center w-full font-medium">
        {date
          ? new Date(date).toString().slice(0, 15)
          : new Date().toString().slice(0, 15)}
      </p>
    </div>
  )
}
